<!-- 产品与服务模块组件 -->
<template>
  <div class="mobile-product-service">
    <div class="item" v-for="item in productServiceData" :key="item.id" @click="goDetail(item.id)">
      <div class="left">
        <div class="title">{{ item.title }}</div>
        <div class="descript" v-for="(d, index) in item.descript" :key="`${item.id}_${index}`">{{ d }}</div>
      </div>
      <div class="right">
        <img :src="require(`@/assets/image/mobile/photo-${item.image}.png`)">
      </div>
    </div>
  </div>
</template>

<script>
  import productServiceData from './product-service-data'

  export default {
    name: 'MobileProductService',
    data () {
      return {
        productServiceData: productServiceData
      }
    },
    methods: {
      // 跳转至详情页
      goDetail (id) {
        this.$router.push({
          path: `/mobileProductServiceDetail/${id}`
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/components/mobile/style/variable.scss';

  .mobile-product-service {
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    .item {
      flex: 1;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      // min-height: 2.48rem;
      border-bottom: 1px solid $border-color-2;
      .left {
        flex: 1;
        .title {
          font-size: $font-size-1;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: $text-color-1;
          margin-bottom: $distance-1;
        }
        .descript {
          font-size: $font-size-2;
          color: $text-color-2;
          font-weight: 400;
          line-height: 0.36rem;
          padding-left: 0.22rem;
          position: relative;
          margin-bottom: 0.1rem;
          &:before {
            content: '';
            width: 0.08rem;
            height: 0.08rem;
            border-radius: 0.04rem;
            background-color: $text-color-3;
            position: absolute;
            left: 0;
            top: 0.14rem;
          }
        }
      }
      .right {
        width: 1.92rem;
        height: 2.58rem;
        // margin-left: 0.8rem;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>