<!-- 首页 -->
<template>
  <div class="mobile-container mobile-home" v-title="'微官网'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="body-wrapper">
        <div class="video">
          <!-- <video width="100%" height="100%" autoplay="autoplay" loop="loop" muted style="object-fit: cover;">
            <source src="@/assets/video/home.mp4" type="video/mp4"/>
          </video>
          <div class="video-shade">
            <span>以科技驱动</span>
            <span>高效物流供应链</span>
          </div> -->
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="swiper-slide-wrapper">
                  <img src="@/assets/image/mobile/photo-56.jpg">
                  <div class="swiper-slide-content">
                    <span>以科技驱动</span>
                    <span>高效物流供应链</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="swiper-slide-wrapper">
                  <img src="@/assets/image/mobile/photo-57.jpg">
                  <div class="swiper-slide-content">
                    <span>数智物流</span>
                    <span>全链服务产品体系</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="swiper-slide-wrapper">
                  <img src="@/assets/image/mobile/photo-58.jpg">
                  <div class="swiper-slide-content">
                    <span>一体化的行业</span>
                    <span>物流解决方案</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">智慧物流全链服务集成商</div>
          <div class="section-body">
            <mobile-product-service></mobile-product-service>
          </div>
        </div>
        <div class="section">
          <div class="section-title">一体化的物流技术解决方案</div>
          <div class="section-body">
            <mobile-solution></mobile-solution>
          </div>
        </div>
        <div class="section">
          <div class="section-title">全维度应用覆盖的物流云平台</div>
          <div class="section-body">
            <mobile-cloud-platform></mobile-cloud-platform>
          </div>
        </div>
        <div class="section">
          <div class="section-title">全套WEB环境敏捷开发工具</div>
          <div class="section-body">
            <mobile-developer></mobile-developer>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <mobile-footer></mobile-footer>
    </div>
  </div>
</template>

<script>
// 头部
import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
import MobileProductService from '@/components/mobile/mobile-product-service/mobile-product-service'
import MobileSolution from '@/components/mobile/mobile-solution/mobile-solution'
import MobileCloudPlatform from '@/components/mobile/mobile-cloud-platform/mobile-cloud-platform'
import MobileDeveloper from '@/components/mobile/mobile-developer/mobile-developer'
import MobileFooter from '@/components/mobile/mobile-footer/mobile-footer'

export default {
  name: 'MobileHome',
  components: {
    MobileHeader,
    MobileProductService,
    MobileSolution,
    MobileCloudPlatform,
    MobileDeveloper,
    MobileFooter
  },
  methods: {
    // 初始化
    init () {
      this.$nextTick(() => {
        new Swiper('.swiper-container', {
          pagination: '.swiper-pagination',
          paginationClickable: true,
          autoplay: 5000,
          autoplayStopOnLast: false
        })
      })
    },
    GetQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
      var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
      var context = ""
      if (r != null)
        context = r[2]
      reg = null
      r = null
      return context == null || context == "" || context == "undefined" ? "" : context
    },
    handleCode (code) {
      if (!code) return
      // if (code.length < 10) return
      localStorage.setItem('wechatCode', code)
      this.$router.push('/applicationUseMobile')
    },
  },
  created () {
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss">
@import '@/components/mobile/style/common.scss';

.mobile-home {
  .body {
    .body-wrapper {
      background: url(~@/assets/image/mobile/bg-11.png) repeat-y left 3.48rem;
      background-size: 100%;

      .video {

        // width: 100%;
        // height: 3.96rem;
        // position: relative;
        // video {
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        // }
        // .video-shade {
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        //   top: 0;
        //   bottom: 0;
        //   background-color: rgba(0, 0, 0, 0.5);
        //   font-size: 0.4rem;
        //   font-family: Source Han Sans CN;
        //   font-weight: 500;
        //   color: $white-color;
        //   line-height: 0.48rem;
        //   padding-top: 1.07rem;
        //   padding-left: 1.37rem;
        //   span {
        //     display: block;
        //   }
        // }
        .swiper-container {
          .swiper-pagination-bullet {
            width: 0.2rem;
            height: 0.06rem;
            border-radius: 0;
            border: none;
            background-color: $white-color;
            opacity: 1;
          }

          .swiper-pagination-bullet-active {
            border: none;
            background-color: $text-color-6;
          }

          &.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
            margin: 0.4rem 0.06rem;
          }
        }

        .swiper-slide-wrapper {
          position: relative;

          .swiper-slide-content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.36);
            font-size: 0.4rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: $white-color;
            line-height: 0.48rem;
            padding-left: 0.8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      .section {
        .section-title {
          margin: 0.6rem 0 0.4rem;
          text-align: center;
          font-size: $font-size-8;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: $text-color-1;
        }

        .section-body {
          .mobile-product-service {
            min-height: 9.54rem;
            border-top: 1px solid $border-color-2;
          }

          .mobile-cloud-platform {
            min-height: 11.25rem;
            border-top: 1px solid $border-color-2;
          }

          .mobile-developer {
            padding-top: 0.43rem;
          }
        }
      }
    }
  }
}
</style>
