<!-- 解决方案模块组件 -->
<template>
  <div class="mobile-solution">
    <div class="item" v-for="item in solutionData" :key="item.id" @click="goDetail(item.id)">
      <div class="bg">
        <img :src="require(`@/assets/image/mobile/photo-${item.image}.png`)">
      </div>
      <div class="content">
        <div class="left">
          <div class="title">{{ item.title }}</div>
          <div class="descript">{{ item.descript }}</div>
        </div>
        <img src="@/assets/image/mobile/arrow-right-white.png" class="mobile-arrow-right">
      </div>
    </div>
  </div>
</template>

<script>
  import solutionData from './solution-data'

  export default {
    name: 'MobileSolution',
    data () {
      return {
        solutionData: solutionData
      }
    },
    methods: {
      // 跳转至详情页
      goDetail (id) {
        this.$router.push({
          path: `/mobileSolutionDetail/${id}`
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/components/mobile/style/variable.scss';

  .mobile-solution {
    margin: $distance-1 $distance-1 0;
    // border: 1px solid $border-color-2;
    // background-color: $white-color;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .item {
      border-radius: 0.08rem;
      overflow: hidden;
      position: relative;
      margin-bottom: $distance-1;
      .bg {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.4) 100%);
          opacity: 0.64;
        }
      }
      .content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $distance-2 0 0.6rem;
        .left {
          width: 3.1rem;
        }
        .title {
          font-size: $font-size-1;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: $white-color;
        }
        .descript {
          margin-top: $distance-1;
          font-size: $font-size-2;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: $white-color;
          line-height: 0.36rem;
        }
      }
      .mobile-arrow-right {
        width: 0.12rem;
        height: 0.2rem;
        margin-left: $distance-2;
      }
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
</style>